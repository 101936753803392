<template>
  <div
    >
    <a
      class="caption text-decoration-none grey--text text--darken-2"
      href="https://app.partnersfans.cl/terminos-y-condiciones.pdf"
      >
      <template v-if="disclaimer">Al continuar está aceptando nuestros </template>
      <span
        class="blue--text font-weight-bold"
        >
        Términos y condiciones
      </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    disclaimer: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
